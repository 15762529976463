<template>
    <div class="datas_upper">
        <b-container fluid class="datas_container">
            <b-row no-gutters class="datas_wrap" align-h="center" align-v="center">
                <b-col lg="2" md="4" sm="6" v-for="(data,index) in datas" :key="index">
                    <h3 v-html="data.title"></h3>
                    <p v-html="data.subtitle"></p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    props:{
        datas: Array
    }
}
</script>

<style scoped>
.datas_container{
    padding: 35px 0 10px;
}
.datas_container .datas_wrap > div{
    max-width: max-content;
    flex: 0 1 auto;
}
.datas_container .datas_wrap > div:first-child h3{
    color: var(--primario);
}
.datas_container .datas_wrap h3{
    font-size: 40px;
    font-weight: 900;
    letter-spacing: -0.04px;
    line-height: 1.27;
    color: var(--line);
    letter-spacing: -0.02px;
    text-align: center;
}
.datas_container .datas_wrap p{
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    color: var(--line);
    text-align: center;
    border-bottom: 1px dashed var(--line);
    margin: 0 20px;
}
@media(max-width: 576px){
    .datas_container .datas_wrap > div{
        margin: 0 8%;
    }
}
</style>
<template>
  <div class="home_section">
    <Banner :data="bannerData" />
    <RowRed :data="redData" />
    <ImageSide :data="imageSide" />
    <DatasRow :datas="DatasRow" />
    <Texting :data="textData" />
    <ProyectosTabs :proyectos="proyectos" v-if="proyectos" />
    <Contacto />
  </div>
</template>

<script>
import Banner from "@/components/shared/Banner.vue";
import RowRed from "@/components/shared/RowRed.vue";
import ImageSide from "@/components/shared/ImageSide.vue";
import DatasRow from "@/components/shared/DatasRow.vue";
import Texting from "@/components/shared/Text.vue";
import ProyectosTabs from "@/components/shared/ProyectosTabs/index.vue";
import Contacto from "@/components/shared/Contacto.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Banner,
    RowRed,
    ImageSide,
    DatasRow,
    Texting,
    ProyectosTabs,
    Contacto,
  },
  data() {
    return {
      
    };
  },
  methods: {
    ...mapActions({
      getProyectos: "proyectos/getProyectos",
    }),
  },
  computed: {
    ...mapGetters({
      proyectos: "proyectos/proyectosDestacados",
      proyectoActivo: "proyectos/proyectoActivo",
    }),
    bannerData() {
      return{
        bg: "img/CDMX-2.jpg",
        title: this.$t('home.banner.title'),
        btn: this.$t('home.banner.btn'),
        link: "somos",
      }
    },
    redData(){
      var redDatas = Array();
      redDatas.push(
      {
        icon: "svg/ico-gestion.svg",
        title: this.$t('home.redCTA.gestion.title'),
        description: this.$t('home.redCTA.gestion.desc'),
        more: this.$t('home.redCTA.gestion.btn'),
      });
      redDatas.push(
      {
        icon: "svg/ico-comercializacion.svg",
        title: this.$t('home.redCTA.comer.title'),
        description: this.$t('home.redCTA.comer.desc'),
        more: this.$t('home.redCTA.comer.btn'),
      });
      redDatas.push(
      {
        icon: "svg/ico-consultoria.svg",
        title: this.$t('home.redCTA.consul.title'),
        description: this.$t('home.redCTA.consul.desc'),
        more: this.$t('home.redCTA.consul.btn'),
      });
      return redDatas;
    },
    imageSide() {
      return{
        lineUp: true,
        upTitle: this.$t('home.somos.upTitle'),
        lineTitle: false,
        title: this.$t('home.somos.title'),
        textLeft: this.$t('home.somos.textLeft'),
        imgRight: "img/quienes-somos.jpg"
      }
    },
    DatasRow(){
      var datasRows = Array();
      datasRows.push(
      {
        title: "+50",
        subtitle: this.$t('home.number.exp'),
      });
      datasRows.push(
      {
        title: "+950,000",
        subtitle: this.$t('home.number.sold'),
      });
      datasRows.push(
      {
        title: "+500,000",
        subtitle: this.$t('home.number.office'),
      });
      datasRows.push(
      {
        title: "+200,000",
        subtitle: this.$t('home.number.malls'),
      });
      datasRows.push(
      {
        title: "+30",
        subtitle: this.$t('home.number.corporate'),
      });
      datasRows.push(
      {
        title: "+100,000",
        subtitle: this.$t('home.number.projects'),
      });
      return datasRows;
    },
    textData() {
      return{
        title: this.$t('home.number.title'),
        lBottom: true,
      }
    },
  },
  async mounted() {
    await this.getProyectos();
    this.$store.commit("proyectos/SET_PROYECTO_ACTIVO", this.proyectos[0].id);
  },
};
</script>

<style scoped></style>
